:root {
  --border-gray: #b7bbbd;
  --yellow-dark: #fac100;
  --yellow-light: #fde697;
  --section-gap: 6px;

  --tomato: #ff6347;
  --tomato-light: #ffab9c;

  --green: #94e238;
  --beige: #947334;
  --brown: #563806;
}

html,
body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li,
a {
  margin: 0;
  padding: 0;
}
li,
li > a {
  text-decoration: none;
  list-style: none;
  color: #000;
}

input {
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 0 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
}
input::placeholder {
  color: var(--border-gray);
}
input:focus {
  outline: none;
  border-color: #000;
}

textarea {
  width: 100%;
  height: 130px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;

  resize: none;
}
textarea::placeholder {
  color: var(--border-gray);
}

label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  margin-bottom: 10px;
}

.file-input-container {
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: 0 10px 0 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.file-input-container > input[type="file"] {
  position: absolute;
  top: -1px;
  left: -1px;
  border: none;
  width: 1px;
  height: 1px;

  pointer-events: none;
}

.btn-small {
  height: 32px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: #fff;
}

.flex-row {
  display: flex;
}
.flex-row-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-col-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-col-between-start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

main {
  background-color: #fff;
  flex: 1;
  overflow: auto;
}

/* modal */
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000aa;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-visible {
  display: flex;
}
.modal-base-inner {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  padding: 40px 68px;
  min-width: 480px;
  max-height: 100%;
  overflow-y: auto;
}
.modal-base-inner::-webkit-scrollbar {
  appearance: none;
}
.modal-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: center;
}

/* modal-yellow */
.modal-yellow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000aa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.modal-yellow-container > .inner {
  position: relative;

  width: 344px;
  max-height: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

/* button */
.button-base {
  width: 100%;
  height: 52px;
  background-color: var(--yellow-dark);
  border-radius: 8px;
  cursor: pointer;

  font-size: 24px;
  font-weight: 700;
  line-height: 28.64px;
  color: #fff;
}
.button-base-outline {
  width: 100%;
  height: 52px;
  background-color: #fff;
  border: 1px solid var(--yellow-dark);
  border-radius: 8px;
  cursor: pointer;

  font-size: 21px;
  font-weight: 400;
  line-height: 28.64px;
  color: var(--yellow-dark);
}
.button-base-disabled {
  background-color: var(--border-gray);
  color: #fff;
}
.button-base-outline-disabled {
  border-color: var(--border-gray);
  color: var(--border-gray);
}

p.alert-desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 11.93px;
  color: var(--tomato);
}
p.label-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: center;
}
p.value-big {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
}
p.value-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
}
p.username {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  color: var(--brown);
}

/* select */
.select-container {
  position: relative;
  /* min-width: 60px; */
  height: 48px;
  display: flex;
  align-items: center;

  border: 1px solid var(--yellow-dark);
  border-radius: 8px;

  overflow-y: hidden;
}
select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  min-width: 100px;
  height: 100%;
  outline: none;
  border: none;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  color: var(--yellow-dark);

  padding: 0 20px;
}
.select-container > span {
  position: absolute;
  right: 8px;
}

/* GNB */
.gnb-wrapper {
  position: relative;
}
.gnb {
  padding: 20px 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gnb > .logo {
  width: 200px;
  height: 48px;
}
.gnb > .menu-container {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.gnb > .menu-container > li,
.gnb > .menu-container > li > a {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gnb > .menu-container a {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
  text-align: center;
  color: var(--border-gray);
}
.gnb > .menu-container a.current {
  color: var(--yellow-dark);
}

.global-post-filter {
  display: flex;
  background-color: #fff;
  padding: 0 30px 30px 30px;
  gap: 35px;
  flex-wrap: wrap;
}

.sub-bar-container {
  border-top: 1px solid var(--border-gray);
  border-bottom: 1px solid var(--border-gray);
  background-color: #fff;
  gap: 20px;
}
.sub-bar-container > a {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
  text-align: center;
  color: var(--border-gray);
  padding: 20px;
  text-decoration: none;
}
.sub-bar-container > a.current {
  color: var(--yellow-dark);
}

.shadow {
  box-shadow: 2px 3px 6px 2px #00000040;
}

/* checkbox */
.checkbox-container {
  cursor: pointer;
}
.checkbox-container > span.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid var(--yellow-dark);
  border-radius: 4px;
  background-color: #fff;
  margin-right: 8px;
}
.checkbox-container > span.checkbox.checked {
  background-color: var(--yellow-dark);
}
.checkbox-container > span.label {
}

/* search */
.search-container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--yellow-dark);
  border-radius: 8px;

  width: 100%;

  padding-right: 20px;
}
.search-container > .search-input {
  flex: 1;
  border: none;
  height: 48px;
  margin: 0 14px;
  padding: 0;
}
/* 검색아이콘 */
.search-container > img {
  width: 22px;
  height: 22px;
}

.search-office-result-wrapper {
  flex: 1;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  margin: 24px 0;
}

.search-office-result {
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 8px;
  cursor: pointer;
}
.search-office-result-selected {
  border-color: var(--yellow-dark);
}
.search-office-result-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: left;
  margin-bottom: 4px;
}
.search-office-result-address {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: left;
}

/* footer */
.footer {
  padding: 14px 0;
  background-color: var(--yellow-light);
}

.footer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
}

/* 뉴스, 공지사항 */
.news-page-wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-page-inner {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-page-header {
  width: 100%;
  height: 161px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #000;
}

.news-page-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 33.41px;
}

.news-row {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid var(--border-gray);

  font-weight: 700;
}
.news-row > span:nth-child(1) {
  width: 170px;
  color: var(--brown);
  text-align: center;
}
.news-row > span:nth-child(2) {
  flex: 1;

  cursor: pointer;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
}
.news-row > span:nth-child(3) {
  width: 150px;
  color: var(--border-gray);
  text-align: center;
}

/* text */
.text-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.71px;
}
.text-medium {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
}
.text-big {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.64px;
}
.text-big2 {
  font-size: 28px;
  font-weight: 400;
  line-height: 33.41px;
}
.bold {
  font-weight: 700;
}

/* 매물등록 */
.post-register-page-wrapper {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: auto;
  padding-top: 90px;
  padding-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;
}

/* 직원관리 */
.employee-container {
  width: 48%;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-bottom: 30px;
  cursor: pointer;
}

/* pagination */

.pagination-wrapper {
  margin: 20px 0;
}
.pagination-wrapper button.pagination-prev,
.pagination-wrapper button.pagination-next {
  padding: 0;
  width: 36px;
  height: 36px;
  background-color: #fff;
}
.pagination-wrapper .pagination-prev > img,
.pagination-wrapper .pagination-next > img {
  width: 100%;
  height: 100%;
}
.pagination-wrapper button.pagination-num {
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;

  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: center;

  margin: 0 4px;
}
.pagination-wrapper button.pagination-num-current {
  background-color: var(--yellow-dark);
  cursor: default;
}

/* map */
.customer-marker-container {
  position: relative;
}
.customer-marker-container > .customer-marker {
  width: 65px;
  height: 117px;
}
.customer-marker-container > .customer-marker > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.customer-marker-container > .customer-marker > .customer-marker-cost {
  position: relative;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 700;
  padding: 6px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  border-radius: 24px;
}
.customer-marker-container > .customer-marker-postcount {
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
}

.customer-marker-container > .customer-marker-postcount > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-marker-container > .customer-marker-postcount > span {
  position: relative;
  font-size: 11px;
  font-weight: 700;
  line-height: 16.71px;
  text-align: center;
  color: var(--brown);
}

/* 지도 위 매물목록 */
.map-postlist-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
}
.map-postlist-container > div {
  position: relative;
  width: 400px;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 0;
  /* margin-top: 35px; */
  overflow-y: auto;
}
.map-postlist-container > div:nth-child(2) {
  padding: 0;
  margin-left: 10px;
}

.custom-dot-list {
}
.custom-dot-list .react-multi-carousel-dot button {
  border: 1px solid var(--yellow-dark);
  background-color: #fff;
}
.custom-dot-list .react-multi-carousel-dot--active button {
  background-color: var(--yellow-dark);
}

.left-apt-info {
  width: 400px;
  height: 100%;
  border-radius: 32px;
  top: 20px;
  left: 0px;
  position: absolute;
  z-index: 1000000;
  background-color: #fff;
}

.left-yellow-box {
  background-color: #fac100;
  height: 140px;
  border-radius: 32px 32px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-circle-wrap {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}

.react-multi-carousel-item {
  /* width: 250px !important; */
  /* height: 150px !important; */
}

.react-multi-carousel-list {
  border-radius: 12px;
}

/* 1080px 이하 화면 크기에서만 적용 */
@media (max-width: 1080px) {
  .react-multi-carousel-list {
    /* margin-left: 26px !important;/ */
  }

  .zzzzzz > div > img {
    width: 100% !important;
  }

  .customer-marker-container > .customer-marker {
    width: 55px !important;
  }

  .customer-marker-container > .customer-marker > .customer-marker-cost {
    width: 55px !important;
    height: 55px !important;
    font-size: 10px !important;
  }
  .customer-marker-container > .customer-marker-postcount {
    width: 16px Im !important;
    height: 16px Im !important;
  }
}

.zzzzzz {
  width: 100%;
}
